import { css } from '@emotion/react'

import { mediaStyle } from './theme'

export default css`
  body {
    min-width: 100%;
    min-height: 100%;
    background: white;
  }

  img {
    width: 100%;
  }

  #fullpage {
    .section > .fp-tableCell {
      overflow: hidden;
    }
  }

  *, *::before, &::after {
    border-color: black;
  }

  input:focus,
  textarea:focus {
    border-color: black !important;
    box-shadow: 0 0 0 1px black !important;
  }

  ${mediaStyle}
`;
