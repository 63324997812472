/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'focus-visible'
import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';

import wrapWithProvider from './with-provider';

export const wrapRootElement = wrapWithProvider;

(async () => {
  if (!window.ResizeObserver) {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default;
  }
  const firebaseConfig = {
    apiKey: "AIzaSyC0Vyt31szoof7fZBwEtMdNOUDgTY_qG48",
    authDomain: "what-the-data.firebaseapp.com",
    databaseURL: "https://what-the-data-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "what-the-data",
    storageBucket: "what-the-data.appspot.com",
    messagingSenderId: "484367719776",
    appId: "1:484367719776:web:9c8aea9179b1bcb7545205"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getFunctions(app)
})()

if (process.env.NODE_ENV === 'development') {
  const { getLCP, getFID, getCLS } = require('web-vitals');
  getCLS(console.log);
  getFID(console.log);
  getLCP(console.log);
  window.gtag = console.log
}
