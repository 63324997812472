import React from 'react'
import { SWRConfig } from 'swr'

import ThemeProvider from './src/components/ThemeProvider'

const fetcher = (...args) => fetch(...args).then(res => res.json())

const Providers = ({ element }) =>
  <ThemeProvider>
    <SWRConfig value={{ fetcher }}>
      {element}
    </SWRConfig>
  </ThemeProvider>

export default Providers
