exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-edu-1-ans-js": () => import("./../../../src/pages/app/edu-1/ans.js" /* webpackChunkName: "component---src-pages-app-edu-1-ans-js" */),
  "component---src-pages-app-edu-1-index-js": () => import("./../../../src/pages/app/edu-1/index.js" /* webpackChunkName: "component---src-pages-app-edu-1-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-measure-exam-js": () => import("./../../../src/pages/measure/exam.js" /* webpackChunkName: "component---src-pages-measure-exam-js" */),
  "component---src-pages-measure-index-js": () => import("./../../../src/pages/measure/index.js" /* webpackChunkName: "component---src-pages-measure-index-js" */),
  "component---src-pages-play-datafriend-js": () => import("./../../../src/pages/play/datafriend.js" /* webpackChunkName: "component---src-pages-play-datafriend-js" */),
  "component---src-pages-play-index-js": () => import("./../../../src/pages/play/index.js" /* webpackChunkName: "component---src-pages-play-index-js" */),
  "component---src-pages-play-taiwan-100-js": () => import("./../../../src/pages/play/taiwan100.js" /* webpackChunkName: "component---src-pages-play-taiwan-100-js" */),
  "component---src-pages-souvenir-js": () => import("./../../../src/pages/souvenir.js" /* webpackChunkName: "component---src-pages-souvenir-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

